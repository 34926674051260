export default class MeatTheCast {
    constructor(){
        this.iconNavs = document.querySelectorAll('#js-meat-the-cast-nav li');
        this.activeIconNav = document.querySelector('#js-meat-the-cast-nav li.active');
        this.castDetailContainer = document.getElementById('js-meat-the-cast-detail');
        this.activeDetailSlide = document.querySelector('.js-meat-the-cast-detail_slide.active');
        
        this.prevArrow = document.getElementById('js-meat-the-cast-prev-arrow');
        this.nextArrow = document.getElementById('js-meat-the-cast-next-arrow');

        let iconNavsArray = Array.from(this.iconNavs);
        this.veggies = iconNavsArray.map(v => v.dataset.veggie);
    }

    initMeatTheCast(){
        //icons
        this.iconNavs.forEach(icon => {
            icon.addEventListener('click', e => {                
                e.preventDefault();                
                //remove active icon on meat the cast icon nav and set new one
                this.removeActiveIconNav();
                this.setActiveIconNav(icon);                

                //remove active slide and add new one
                this.removeActiveDetailSlide();
                this.setActiveDetailSlide(icon);
            });
        });

        //arrows
        this.prevArrow.addEventListener('click', (e) => {
            e.preventDefault();
            this.removeActiveDetailSlide();
            this.setActiveDetailSlide(this.prevArrow);
            this.handleArrowClick('prev', parseInt(this.prevArrow.dataset.index));
        });

        this.nextArrow.addEventListener('click', e => {
            e.preventDefault();
            this.removeActiveDetailSlide();
            this.setActiveDetailSlide(this.nextArrow);
            this.handleArrowClick('next', parseInt(this.nextArrow.dataset.index));
        });
    }
    
    setActiveIconNav(icon) {
        icon.classList.add('active');                
        this.activeIconNav = icon;
    }

    removeActiveIconNav() {
        this.activeIconNav.classList.remove('active');
    }

    //item can refer to any element with the data-index attribute
    setActiveDetailSlide(item) {
        let _index = item.dataset.index;        
        let _indexId = `.js-meat-the-cast-detail_slide[data-index="${_index}"]`;

        let _newActiveSlide = document.querySelector(_indexId);


        this.castDetailContainer.className = `meat-the-cast-detail ${_newActiveSlide.dataset.veggie}`;
        _newActiveSlide.classList.add('active');
        this.activeDetailSlide = _newActiveSlide;
    }

    removeActiveDetailSlide() {
        this.activeDetailSlide.classList.remove('active');
    }

    handleArrowClick(direction, index) {        
        
        //increasing / decreasing values for upcoming slide
        if (direction === 'prev') {
            if (index == 0) {
                this.prevArrow.setAttribute('data-index', this.veggies.length - 1);
            } else {
                this.prevArrow.setAttribute('data-index', index - 1);
            }
        } else {
            if (index == (this.veggies.length - 1)) {
                this.nextArrow.setAttribute('data-index', 0);                
            } else {
                this.nextArrow.setAttribute('data-index', index + 1);
            }
        }
    }
}