import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.js';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class Animations {
    constructor() {
        
    }

    initAnimations() {
        this.initTopHero();        
        this.initVeggiesVsJunkies();
        this.initWeAreVeggieMates();
        this.initJoinTheFight();
        this.initBuyGetVeggies();
    }

    initTopHero() {

        let mm = gsap.matchMedia(),
            breakPoint = 52.125;
        
        mm.add({
            isDesktop: `(min-width: ${breakPoint}em)`,
            reduceMotion: '(prefers-reduced-motion: reduce)'
        }, (context) => {
            //check conditions  
            let { isDesktop } = context.conditions;

            //animate primary logo on load
            const logoTL = gsap.timeline({ 
                delay: 0.5,
                scrollTrigger: {                
                    trigger: '#js-hero-intro',
                }
            });

            logoTL.fromTo('#js-vm-primary-logo', { opacity: 0, scale: 2}, { scale: 1, rotation: 360, opacity: 1, duration: 1});
            logoTL.fromTo('#js-hero-top-h1', { opacity: 0}, { opacity: 1, duration: .5});
            logoTL.fromTo('#js-hero-top-copy', { opacity: 0}, { opacity: 1, duration: .25});
            logoTL.fromTo('#js-hero-top-btn-learn-more', { opacity: 0, skewX: 45}, { opacity: 1, skewX: 0, duration: .75, ease: 'bounce.out'});
            if (isDesktop) {
                logoTL.to('#js-orange-slant', { clipPath: 'polygon(0% 0%, 84% 0%, 40% 100%, 0% 100%)', duration: .25, ease: 'none' });
                logoTL.from('#js-hero-intro-broccoli', { y: '-120%', ease: 'elastic'}, '<');
                logoTL.to('#js-hero-intro', { y:5, duration: .1, repeat: 2});
                logoTL.to('#js-hero-intro', { y:0, duration: .1, repeat: 2});
            }
        }); 


        //button click for smooth scroll
        const learnMoreBtn = document.getElementById('js-hero-top-btn-learn-more');
        learnMoreBtn.addEventListener('click', this.handleLearnMoreClick);

    }   

    handleLearnMoreClick(evt) {
        evt.preventDefault();
        gsap.to(window, {duration: 1, scrollTo:{y:'#js-hero-veggies-vs-junkies', offsetY: -20}});
    }

    initVeggiesVsJunkies() {
        const hitmanTL = gsap.timeline({
            repeat: 4,
            scrollTrigger: {                
                trigger: '#js-hero-veggies-vs-junkies',
                start: '10px 10px',
                end: 'bottom bottom',
                onLeave: () => {
                    hitmanTL.pause();
                    hitmanTL.seek(0);
                }
            }
        });
    
        hitmanTL.to('.hvj-tile_junkie #smoke-1', { opacity: 1, duration: .25});
        hitmanTL.to('.hvj-tile_veggie #left-hand', { y: 100, duration: .25}, '<');
        hitmanTL.to('.hvj-tile_junkie #smoke-2', { opacity: .75, duration: .25});
        hitmanTL.to('.hvj-tile_veggie #left-hand', { y: 0, duration: .25}, '<');
        hitmanTL.to('.hvj-tile_veggie #right-hand', { y: 100, duration: .25}, '<');
        hitmanTL.to('.hvj-tile_junkie #smoke-3', { opacity: .5, duration: .25});      
        hitmanTL.to('.hvj-tile_veggie #left-hand', { y: 100, duration: .25}, '<');
        hitmanTL.to('.hvj-tile_veggie #right-hand', { y: 0, duration: .25}, '<');          
        hitmanTL.to('.hvj-tile_junkie #smoke-1', { opacity: 0, duration: .25}); 
        hitmanTL.to('.hvj-tile_veggie #left-hand', { y: 0, duration: .25}, '<');
        hitmanTL.to('.hvj-tile_veggie #right-hand', { y: 100, duration: .25}, '<');
        hitmanTL.to('.hvj-tile_junkie #smoke-2', { opacity: 0, duration: .25});
        hitmanTL.to('.hvj-tile_junkie #smoke-3', { opacity: 0, duration: .25});
        hitmanTL.to('.hvj-tile_veggie #right-hand', { y: 0, duration: .25}, '<');                    
    }

    initWeAreVeggieMates() {
        const weareveggieTL = gsap.timeline({            
            scrollTrigger: {                
                trigger: '#js-hero-we-are-veggiemates',
                start: '10px 10px',
                end: 'bottom bottom',
            }
        });
    
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_1 img', { rotate: 5, duration: .15});
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_1 img', { rotate: -5, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_1 img', { rotate: 0, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_1 h3', { scale: 1.1, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_1 h3', { scale: 1, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_2 img', { rotate: 5, duration: .15});
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_2 img', { rotate: -5, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_2 img', { rotate: 0, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_2 h3', { scale: 1.1, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_2 h3', { scale: 1, duration: .15});          
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_3 img', { rotate: 5, duration: .15});
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_3 img', { rotate: -5, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_3 img', { rotate: 0, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_3 h3', { scale: 1.1, duration: .15});                  
        weareveggieTL.to('#js-hero-we-are-veggiemates .hwav-tile_3 h3', { scale: 1, duration: .15});          
    }

    initJoinTheFight() {
        const jointheFightTL = gsap.timeline({
            scrollTrigger: {                
                trigger: '#js-hero-join-the-fight',        
                start: 'top center',
                end: 'top 100px',
                scrub: 3
            }
        });

        jointheFightTL.from('#js-jtf-tickler', { x: '-100%'});
        jointheFightTL.from('#js-jtf-hitman', { x: '-100%'}, '<');
        jointheFightTL.from('#js-jtf-popcorn', { x: '-100%'}, '<');
        jointheFightTL.from('#js-jtf-squash', { x: '100%'});
        jointheFightTL.from('#js-jtf-bazooka-brock', { x: '100%'}, '<');
        jointheFightTL.from('#js-jtf-cheeto-head', { x: '100%'}, '<');
        jointheFightTL.from('#js-jtf-spudz', { y: '100%'});
    }

    initBuyGetVeggies() {

        const buygetveggiesTL = gsap.timeline({
            scrollTrigger: {                
                trigger: '#js-hgbv-footer',        
                start: '40$ center',
                end: 'top 100px',
                scrub: 3
            }
        });

        buygetveggiesTL.from('#js-bgw-donut', { x: '100%'});
    }
}