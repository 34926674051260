import Splide from '@splidejs/splide';

export default class ComicStrip {
    constructor() {        
        this.splideCaption = document.getElementById('js-splide-caption');        
    }

    getSlideCaption() {
        let activeSlide = document.querySelector('.splide__slide.is-active figcaption');
        this.splideCaption.textContent = activeSlide.textContent;
    }

    createComicSlider(){
        const splide = new Splide('#js-comic-splide', {
            type: 'loop',
            direction: 'rtl',
            perPage: 1,
            pagination: true,
        }).mount();

        
        this.getSlideCaption();

        let _obj = this;
        splide.on('moved', function () {            
            _obj.getSlideCaption();
        });
    }
};