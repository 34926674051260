export default class GlobalNav {
    constructor() {
        this.body = document.getElementById('js-body');
        this.btnMenu = document.getElementById('js-btn-global-menu');
        this.internalLinks = document.querySelectorAll('.js-primary-item_int');
        this.menuOpenClass = 'menu-open';
    }

    initGlobalNav() {        
        this.btnMenu.addEventListener('click', () => {
            this.body.classList.toggle(this.menuOpenClass);
        });

        document.addEventListener('keydown', ({key}) => {
            if (key === 'Escape') {
                this.body.classList.remove(this.menuOpenClass);
            }
        });

        this.internalLinks.forEach(icon => {
            icon.addEventListener('click', e => {                                
                this.body.classList.remove(this.menuOpenClass);
            });
        });
    }
}