import ComicStrip from './components/comicstrip.mjs';
import GlobalNav from './components/globalnav.mjs';
import MeatTheCast from './components/meatthecast.mjs';
import Animations from './components/animations.mjs';

//import css to automatically inject in dist package
import '../css/global.css';


document.addEventListener('DOMContentLoaded', function () {

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('service-worker.js');
    }

    const comicStrip = new ComicStrip();
    comicStrip.createComicSlider();

    const globalNav = new GlobalNav();
    globalNav.initGlobalNav();

    const meatTheCast = new MeatTheCast();
    meatTheCast.initMeatTheCast();

    const animations = new Animations();
    animations.initAnimations();
});